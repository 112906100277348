<script setup lang="ts">
import type { DemoRequestType } from "@gasparigit/omnia-sdk";
import type { CreateLinkConfig } from "~/utils/types";

defineProps<{
  linkConfig?: CreateLinkConfig;
}>();

const { data: loggedUser } = useLoggedUser(null, true, null, "logged-user-unauthorized");

const loginOpened = ref(false);
const demoRequestModal = ref<{
  type: DemoRequestType;
  opened: boolean;
}>({
  type: "demo",
  opened: false,
});
</script>

<template>
  <div class="bg-primary-50 rounded-lg mb-5">
    <div class="p-5 md:py-14 md:px-24 text-xl flex flex-col gap-5 items-center justify-center text-center">
      <NuxtImg src="/images/locked.svg" format="webp" class="w-100 h-auto" alt="Background" />
      <h3 class="font-bold text-2xl mt-2 mb-1">Contenuto riservato agli abbonati</h3>
      <p v-if="!loggedUser">Se sei abbonato <Button @click="loginOpened = true" class="mb-4">Effettua l'accesso</Button></p>
      <p v-if="!loggedUser">
        In alternativa, <button class="text-primary font-semibold underline" @click="demoRequestModal = { opened: true, type: 'quotation' }">richiedi un preventivo gratuito</button>.
      </p>
      <p v-else>
        <Button @click="demoRequestModal = { opened: true, type: 'quotation' }" class="mb-4">Richiedi un preventivo gratuito</Button>
      </p>
      <p class="mt-2 mb-1">
        Per essere introdotto al mondo di Omnia, puoi richiedere una demo gratuita del Memoweb, quotidiano informativo e documentale, per essere sempre aggiornati in modo pratico, efficiente e
        completo sulle principali novità normative che coinvolgono gli Enti Locali.
      </p>
      <Button @click="demoRequestModal = { opened: true, type: 'demo' }">Richiedi demo gratuita Memoweb</Button>
    </div>
  </div>

  <FrontLoginDialog v-model:opened="loginOpened" />
  <FrontDemoRequest v-model:open="demoRequestModal.opened" :type="demoRequestModal.type" :link-config="linkConfig" />
</template>
